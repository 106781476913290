<template lang="pug">
thead.v-data-table-header
  tr
    th(v-for="(header, index) in headerRow.props.headers" :key="header.index" 
      :class=`
          [header.class, header.sortable ? 'sortable' : '',
          header.value === headerRow.props.options.sortBy[0] ? 'active sortable' : '', 
          headerRow.props.options.sortDesc[0] ? 'desc': 'asc']
        `
    )
      template(v-if="index === 0")
        v-checkbox(:value="!headerRow.props.everyItem" @click.native="toggleSelectAll" hide-details :key="$route.fullPath").mt-0
      template(v-if="header.sortable")
        span(@click="headerRow.on.sort(header.value)") {{ header.text }}
        <svg @click="headerRow.on.sort(header.value)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="18px" width="18px" role="img" aria-hidden="true" class="v-icon notranslate v-data-table-header__icon v-icon--svg theme--light" style="font-size: 18px; height: 18px; width: 18px;"><path d="M13,20H11V8L5.5,13.5L4.08,12.08L12,4.16L19.92,12.08L18.5,13.5L13,8V20Z"></path></svg>
      template(v-else)
        span {{ header.text }}
      
      portal-target(name="candidates-account" v-if="header.value === 'accountUsername'").header-filter.full-width
      portal-target(name="candidates-license-type" v-if="header.value === 'licenseType'").header-filter.full-width
      portal-target(name="candidates-product" v-if="header.value === 'productName'").header-filter.full-width
      portal-target(name="candidates-status" v-if="header.value === 'status'").header-filter.full-width
      portal-target(name="candidates-date" v-if="header.value === 'mainDate'").header-filter.full-width
      portal-target(name="candidates-reserved" v-if="header.value === 'reserved'").header-filter.full-width
      portal-target(name="candidates-requests" v-if="header.value === 'requests'").header-filter.full-width
      portal-target(name="candidates-lastError" v-if="header.value === 'lastError'").header-filter.full-width
</template>

<script>
export default {
  props: {
    headerRow: {
      type: Object,
      required: true
    }
  },

  methods: {
    toggleSelectAll() {
      return this.headerRow.on['toggle-select-all'](!this.headerRow.props.everyItem)
    }
  }
}
</script>

<style lang="scss" scoped>
.header-filter {
  display: inline-block;
}
</style>
